<template>
    <div class="section">
        <div class="center-section">
            <div class="form-heading">
                <h1 class="header-1">Welcome back</h1>
                <img src="@/assets/wavehand.png" alt="Logo" />
            </div>
            <div class="signup-form">
                <div v-if="errorMessage" class="error-message">
                    {{ errorMessage }}
                </div>
                <form @submit.prevent="handleSubmit">
                    <base-input class="form-group" type="email" v-model="email" placeholder="Email" required
                        icon="envelop">
                    </base-input>
                    <base-input class="form-group" type="password" v-model="password" placeholder="Password" required
                        icon="lock">
                    </base-input>
                    <primary-btn>Sign In</primary-btn>
                    <router-link to="/resetpass" class="reset link caption">Reset password</router-link>
                </form>
            </div>
            <div class="login-link caption">
                Don't have an account? <router-link to="/signup" class="link">Create account</router-link>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, computed } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import BaseInput from '@/components/BaseInput.vue';
import PrimaryBtn from '@/components/PrimaryBtn.vue';

const email = ref('');
const password = ref('');
const localErrorMessage = ref('');

const router = useRouter();
const store = useStore();


const errorMessage = computed(() => localErrorMessage.value || store.getters.getRedirectMessage);

async function handleSubmit() {
    store.dispatch('setRedirectMessage', null);
    
    const url = `${process.env.VUE_APP_BACKEND_URL}/login`;
    const requestBody = {
        email: email.value,
        password: password.value,
    };

    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(requestBody),
        });

        if (!response.ok) {
            throw new Error('Failed to login. Status:' + response.status);
        }

        const result = await response.json();
        const sessionId = result.session_id;
        const userFolderPath = result.user_folder_path;

        store.dispatch('login', { sessionId, userFolderPath, email: email.value });

        router.push('/app');
    } catch (error) {
        console.error('Error during Login:', error);
        localErrorMessage.value = 'Login failed. Please check your email and password.';
    }
}
</script>

<style scoped>
.signup-btn-group {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 1vh;
}

.sign-btn {
    width: 49%;
}

.center-section {
    width: 30%;
    margin: auto;
}

.form-heading {
    display: flex;
    justify-content: center;
    padding-bottom: 1vh;
}

.form-heading img {
    max-width: 100%;
    height: auto;
    object-fit: contain;
}

.signup-form {
    padding: 2.5vh;
    margin-bottom: 24px;
    background-color: rgba(255, 255, 255, 0.1) !important;
    border-radius: 20px;
}

.reset {
    margin-top: 1vh;
    display: flex;
    justify-content: center;
}

.form-group {
    padding: 1vh;
}

.login-link {
    text-align: center;
    color: rgba(255, 255, 255, .4);
}

.link {
    color: white;
    text-decoration: none;
}

.error-message {
    color: red;
    text-align: center;
    margin-bottom: 1vh;
}

@media (max-width: 700px) {
    .center-section {
        width: 80%;
    }

    .signup-form {
        padding: 2vh;
    }

    .form-group {
        padding: 0.5vh;
    }
}
</style>