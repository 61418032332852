<template>
    <div class="actions-group">
        <div class="action-item" @click="handleRedirectUpload">
            <div class="description">
                <p class="header-2">Ava Video</p>
                <p class="caption">
                    Generate a 3D-animation from your video in just a few minutes
                </p>
                <SecondBtn class="action-btn">
                    Start
                </SecondBtn>
            </div>
            <img src="@/assets/video.png" alt="video" />
        </div>

        <div class="action-item prompt-item" @click="handleRedirectPrompt">
            <div class="description">
                <p class="header-2">Ava Prompt</p>
                <p class="caption">
                    Create a unique 3D animation using your prompt and our AI
                </p>
                <SecondBtn class="action-btn">
                    Start
                </SecondBtn>
            </div>
            <img src="@/assets/prompt.png" alt="video" />
        </div>
    </div>

    <ProjectList name="Animations" :projectItems="projectItems"></ProjectList>
    <ProjectList name="Tutorials" :locked="true"></ProjectList>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import SecondBtn from '@/components/SecondBtn.vue';
import ProjectList from '@/components/ProjectList.vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

import { fetchWithAuthCheck } from '@/api/http';

const store = useStore();
const projectItems = ref([]);
const router = useRouter();

function handleRedirectUpload() {
    router.push('/upload');
}

function handleRedirectPrompt() {
    router.push('/prompt');
}

async function fetchProjectItems(sessionId) {
  const url = `${process.env.VUE_APP_BACKEND_URL}/get-animation-list/`;

  try {
    const data = await fetchWithAuthCheck(
      url,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'session-id': sessionId,
        },
      },
      router,
      store 
    );

    projectItems.value = data;
  } catch (error) {
    console.error('Error fetching project items:', error);
    projectItems.value = [];
  }
}

onMounted(() => {
    const sessionId = store.getters.getSessionId;
    fetchProjectItems(sessionId);
});
</script>

<style scoped>
.actions-group {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    width: 100%;
    flex-wrap: wrap;
    gap: 15px;
}

.action-item {
    flex: 1 1 calc(50% - 15px);
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 15px;
    padding: 15px 20px;
    box-sizing: border-box;
    min-width: 250px;
    cursor: pointer;
    position: relative;
}

.action-item:hover:not(.disabled) {
    background-color: rgba(255, 255, 255, 0.2) !important;
    transition-duration: .1s;
}

.action-item img {
    height: 100%;
    width: auto;
    max-width: 42%;
}

.description {
    flex: 1;
    margin-right: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.description .caption {
    color: rgba(255, 255, 255, 0.4);
}

.action-btn {
    width: auto;
    align-self: flex-start;
}

.prompt-item .hover-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition: opacity 0.3s linear;
    border-radius: 15px;
}

.prompt-item:hover .hover-overlay {
    opacity: 1;
}

.overlay-icon {
    margin-bottom: 10px;
}

@media (max-width: 768px) {
    .actions-group {
        flex-direction: column;
        gap: 15px;
    }

    .action-item {
        flex: 1 1 100%;
        align-items: center;
    }

    .action-item img {
        max-width: 100%;
        width: 100px;
        height: auto;
        margin-top: 10px;
    }

    .description {
        margin-right: 0;
    }

    .action-btn {
        width: auto;
        align-self: flex-start;
    }
}
</style>