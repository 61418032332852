export async function fetchWithAuthCheck(url, options = {}, router, store) {
    try {
        const response = await fetch(url, options);

        if (response.status === 401) {
            store.dispatch('setRedirectMessage', 'Your session has expired. Please log in again.');
            store.dispatch('logout');

            router.push({
                path: '/login',
                state: { message: 'Your session has expired. Please log in again.' },
            });

            throw new Error('Unauthorized. Redirecting to login.');
        }

        if (!response.ok) {
            throw new Error(`Fetch failed with status: ${response.status}`);
        }

        const contentType = response.headers.get('Content-Type');
        if (contentType && contentType.includes('application/json')) {
            return await response.json();
        }

        return response;
    } catch (error) {
        console.error('Fetch error:', error);
        throw error;
    }
}
