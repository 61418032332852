<template>
    <ProjectList    name="Animations"
                    :projectItems="projectItems"
                    :isSingleRow="false">

    </ProjectList>
</template>

<script setup>
import { ref, onMounted } from 'vue';

import { useStore } from 'vuex';
import { useRouter } from 'vue-router';

import { fetchWithAuthCheck } from '@/api/http';

const store = useStore();
const router = useRouter();

import ProjectList from '@/components/ProjectList.vue';

const projectItems = ref([]);


async function fetchProjectItems(sessionId, router, store) {
  const url = `${process.env.VUE_APP_BACKEND_URL}/get-animation-list/`;

  try {
    const data = await fetchWithAuthCheck(
      url,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'session-id': sessionId,
        },
      },
      router,
      store 
    );

    projectItems.value = data;
  } catch (error) {
    console.error('Error fetching project items:', error);
    projectItems.value = []; 
  }
}

onMounted(() => {
    const sessionId = store.getters.getSessionId;
    fetchProjectItems(sessionId, router, store);
});


</script>

<style scoped>
.actions-group {
    display: flex;
    align-items: center;
    width: 100%;
    height: 20%;
}

.content-section {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 33%;
}

.content-section-list {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 50px 20px;
    box-sizing: border-box;
    width: 100%;
    border-radius: 15px;
    border: 1px solid rgba(255, 255, 255, .1);
    color: rgba(255, 255, 255, .4);
}

.content-section-list p {
    color: rgba(255, 255, 255, .4);
}

.description p.header-2 {
    margin-bottom: 0;
}

.action-item {
    margin-right: 15px;
    width: 45%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: rgba(255, 255, 255, .1);
    box-sizing: border-box;
    border-radius: 15px;
    padding: 15px 20px;
    cursor: pointer;
}

.action-item:hover {
    background-color: rgba(255, 255, 255, .3);
    transition-duration: .5s;
}

.description .caption {
    color: rgba(255, 255, 255, .4);
    width: 90%;
}

.action-item img {
    height: 100%;
    width: auto;
}

.action-btn {
    border: none;
    border-radius: 5px;
    padding: 5px 10px;
    color: black;
}
</style>